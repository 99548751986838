import React from "react";
import { graphql, withPrefix, Link } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import GetInTouchbtn from "../../components/GetInTouchbtn";
import Img from "gatsby-image";
import n1 from "../../images/news1.jpg";
import n2 from "../../images/news2.jpg";
import n3 from "../../images/news3.jpg";
import n4 from "../../images/news4.jpg";
import n5 from "../../images/news5.jpg";
import n6 from "../../images/news6.jpg";
import n7 from "../../images/news7.jpg";
import n8 from "../../images/news8.jpg";
import n9 from "../../images/news9.jpg";
import n10 from "../../images/news10-1.jpg";
import n11 from "../../images/news11.png";
import n12 from "../../images/news12.jpg";
import n13 from '../../images/news13.png';

const Nnoteworthy = (props) => {
  return (
    <Layout bodyClass="page-noteworthy">
      <Seo
        title="News &amp; Press"
        description="Get updated on our recent developments and adventures."
      />

      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
          />
        </div>
        <div className="container">
          <div className="row main-page">
            <div className="col-12">
              <h1 className="page-title">News &amp; Press</h1>
              <h2 className="page-subtitle">
                Get updated on our recent developments and adventures.
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container py-6">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="card news-box">
                <Link
                  className="wr-card-img"
                  to={
                    "/noteworthy/iTelaSoft-joins-Fintech-Australia-as-an-ecosystem-partner"
                  }
                >
                  <img className="card-img-top" src={n13} />
                </Link>
                <div className="card-body">
                  <Link
                    className="card-title"
                    to={
                      "/noteworthy/iTelaSoft-joins-Fintech-Australia-as-an-ecosystem-partner"
                    }
                  >
                    iTelaSoft joins Fintech Australia as an ecosystem partner
                  </Link>
                  <p className="card-text">
                    In the world of FinTech, where innovation drives progress and disruption are the norm, scaling your business is not just a goal, but a necessity for survival and success […]
                  </p>
                  <Link
                    className="btn-link read-more"
                    to={
                      "/noteworthy/iTelaSoft-joins-Fintech-Australia-as-an-ecosystem-partner"
                    }
                  >
                    Continue Reading
                  </Link>
                </div>
              </div>
            </div>

          <div className="col-12 col-md-6">
              <div className="card news-box">
                <Link
                  className="wr-card-img"
                  to={
                    "/noteworthy/New-Addition-to-Salesforce-MVP-Hall-of-Fame-Sets-Global-Milestone"
                  }
                >
                  <img className="card-img-top" src={n12} />
                </Link>
                <div className="card-body">
                  <Link
                    className="card-title"
                    to={
                      "/noteworthy/New-Addition-to-Salesforce-MVP-Hall-of-Fame-Sets-Global-Milestone"
                    }
                  >
                    New Addition to Salesforce MVP Hall of Fame Sets Global Milestone
                  </Link>
                  <p className="card-text">
                    Chamil Madusanka, Head of Salesforce Practice at Australian-based IT firm iTelaSoft, has become the first ever Sri Lankan to be inducted into the Salesforce MVP Hall of Fame […]
                  </p>
                  <Link
                    className="btn-link read-more"
                    to={
                      "/noteworthy/New-Addition-to-Salesforce-MVP-Hall-of-Fame-Sets-Global-Milestone"
                    }
                  >
                    Continue Reading
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="card news-box">
                <Link
                  className="wr-card-img"
                  to={
                    "/noteworthy/revolutionizing-the-coconut-industry-through-digital-EMP"
                  }
                >
                  <img className="card-img-top" src={n10} />
                </Link>
                <div className="card-body">
                  <Link
                    className="card-title"
                    to={
                      "/noteworthy/revolutionizing-the-coconut-industry-through-digital-EMP"
                    }
                  >
                    Revolutionizing the Coconut Industry through Digital EMP
                  </Link>
                  <p className="card-text">
                    Cultivation remains one of the key export crops in Sri
                    Lanka. With the diversity & demand of coconut-based products
                    increasing globally maintaining & productively managing
                    Coconut Estates would be key to the growth of the industry.{" "}
                    <br />
                    The Pain points associated with managing Coconut Estates
                    have been one of the reasons for Estate owners to […]
                  </p>
                  <Link
                    className="btn-link read-more"
                    to={
                      "/noteworthy/revolutionizing-the-coconut-industry-through-digital-EMP"
                    }
                  >
                    Continue Reading
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="card news-box">
                <Link
                  className="wr-card-img"
                  to={
                    "/noteworthy/iTelaSoft-announces-the-release-of-5G-capabilities-on-its-XG-platform"
                  }
                >
                  <img className="card-img-top" src={n9} />
                </Link>
                <div className="card-body">
                  <Link
                    className="card-title"
                    to={
                      "/noteworthy/iTelaSoft-announces-the-release-of-5G-capabilities-on-its-XG-platform"
                    }
                  >
                    iTelaSoft Announces the Release of 5G Capabilities on its XG
                    Platform
                  </Link>
                  <p className="card-text">
                    After months of R&D plus hard work, iTelaSoft (iTelaSoft Pty
                    Ltd) announced the immediate availability of 5G testing and
                    monitoring functionalities on its XG platform from November
                    2020. “Until now, iTelaSoft’s XG platform was capable of
                    testing and monitoring 2G, 3G, 4G, and Narrowband cellular
                    networks. Hereinafter, with the addition of latest 5G NR
                    capabilities, […]
                  </p>
                  <Link
                    className="btn-link read-more"
                    to={
                      "/noteworthy/iTelaSoft-announces-the-release-of-5G-capabilities-on-its-XG-platform"
                    }
                  >
                    Continue Reading
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="card news-box">
                <Link
                  className="wr-card-img"
                  to={
                    "/noteworthy/How-migrating-to-AWS-eliminated-tech-worries-at-Skwirk"
                  }
                >
                  <img alt="News" className="card-img-top" src={n8} />
                </Link>
                <div className="card-body">
                  <Link
                    className="card-title"
                    to={
                      "/noteworthy/How-migrating-to-AWS-eliminated-tech-worries-at-Skwirk"
                    }
                  >
                    How migrating to AWS eliminated tech worries at Skwirk
                  </Link>
                  <p className="card-text">
                    It was in early 2014 that iTelaSoft took on the
                    responsibility of managing Skwirk online learning platform a
                    leader in delivering K-12 curriculum based content to
                    students and teachers of Australia and the UK schools. It
                    had great content developed by amazing pedagogical and
                    instructional design talent, which made it such a powerful
                    tool loved […]
                  </p>
                  <Link
                    className="btn-link read-more"
                    to={
                      "/noteworthy/How-migrating-to-AWS-eliminated-tech-worries-at-Skwirk"
                    }
                  >
                    Continue Reading
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="card news-box">
                <Link
                  className="wr-card-img"
                  to={
                    "/noteworthy/iTelaSoft-CEO-Honored-at-the-IESL-NSW-Awards"
                  }
                >
                  <img alt="News" className="card-img-top" src={n7} />
                </Link>
                <div className="card-body">
                  <Link
                    className="card-title"
                    to={
                      "/noteworthy/iTelaSoft-CEO-Honored-at-the-IESL-NSW-Awards"
                    }
                  >
                    iTelaSoft CEO Honored at the IESL NSW Awards
                  </Link>
                  <p className="card-text">
                    Sri Lankan engineer, entrepreneur & the CEO of iTelaSoft,
                    Indaka Raigama, has been recognized and awarded in the
                    Institute of Engineers Sri Lanka (IESL), New South Wales
                    Chapter awards ceremony held in Australia recently. The
                    award was given under the category of “Highly Commended
                    Innovation” on a product developed by iTelaSoft under his
                    guidance for […]
                  </p>
                  <Link
                    className="btn-link read-more"
                    to={
                      "/noteworthy/iTelaSoft-CEO-Honored-at-the-IESL-NSW-Awards"
                    }
                  >
                    Continue Reading
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="card news-box">
                <Link
                  className="wr-card-img"
                  to={"/noteworthy/itelasoft-partners-mobitel-on-iot-devices"}
                >
                  <img alt="News" className="card-img-top" src={n6} />
                </Link>
                <div className="card-body">
                  <Link
                    className="card-title"
                    to={"/noteworthy/itelasoft-partners-mobitel-on-iot-devices"}
                  >
                    iTelaSoft Partners Mobitel on IoT Devices
                  </Link>
                  <p className="card-text">
                    iTelaSoft who have been engaged in developing IoT solutions
                    for both global & local organizations, handed over its first
                    set of NB-IoT modems to Mobitel to facilitate its innovative
                    solution platforms. NB-IoT modem was a part of the migration
                    kit which allows existing sensors and solutions based on
                    legacy standards (GPRS, EDGE, UMTS) to be […]
                  </p>
                  <Link
                    className="btn-link read-more"
                    to={"/noteworthy/itelasoft-partners-mobitel-on-iot-devices"}
                  >
                    Continue Reading
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="card news-box">
                <Link
                  className="wr-card-img"
                  to={"/noteworthy/empowering-the-next-generation"}
                >
                  <img alt="News" className="card-img-top" src={n1} />
                </Link>
                <div className="card-body">
                  <Link
                    className="card-title"
                    to={"/noteworthy/empowering-the-next-generation"}
                  >
                    Empowering the next generation
                  </Link>
                  <p className="card-text">
                    iTelaSoft in its program to cascade its knowledge to the
                    next generation of IT professionals participated in an
                    education session for selected students at SLIIT. The
                    session spoke about innovative mobile development platforms
                    which are been used globally. And it was taken by experts
                    from the iTelaSoft team. The students who participated in a
                    […]
                  </p>
                  <Link
                    className="btn-link read-more"
                    to={"/noteworthy/empowering-the-next-generation"}
                  >
                    Continue Reading
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="card news-box">
                <Link
                  className="wr-card-img"
                  to={"/noteworthy/itelasoft-night-2019"}
                >
                  <img alt="News" className="card-img-top" src={n2} />
                </Link>
                <div className="card-body">
                  <Link
                    className="card-title"
                    to={"/noteworthy/itelasoft-night-2019"}
                  >
                    iTelaSoft Celebrating Global Success
                  </Link>
                  <p className="card-text">
                    iTelaSoft, one of the most innovative companies in the IT
                    space celebrated their “Champion Teams” at the annual event
                    held at Jetwing Colombo 7 on the 25 th January. The Company
                    which started as a […]
                  </p>
                  <Link
                    className="btn-link read-more"
                    to={"/noteworthy/itelasoft-night-2019"}
                  >
                    Continue Reading
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="card news-box">
                <Link
                  className="wr-card-img"
                  to={"/noteworthy/new-it-platform-initiative-from-itelasoft"}
                >
                  <img alt="News" className="card-img-top" src={n3} />
                </Link>
                <div className="card-body">
                  <Link
                    className="card-title"
                    to={"/noteworthy/new-it-platform-initiative-from-itelasoft"}
                  >
                    iTelaSoft Leaps into Narrowband IoT
                  </Link>
                  <p className="card-text">
                    Internet of Things (IoT) is a network of online capable
                    physical devices, vehicles, home appliances and many other
                    items. An estimated 30 million objects would be connected to
                    it globally by 2020 with […]
                  </p>
                  <Link
                    className="btn-link read-more"
                    to={"/noteworthy/new-it-platform-initiative-from-itelasoft"}
                  >
                    Continue Reading
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="card news-box">
                <Link
                  className="wr-card-img"
                  to={
                    "/noteworthy/former-navantis-ceo-gives-major-impetus-to-itelasoft"
                  }
                >
                  <img alt="News" className="card-img-top" src={n4} />
                </Link>
                <div className="card-body">
                  <Link
                    className="card-title"
                    to={
                      "/noteworthy/former-navantis-ceo-gives-major-impetus-to-itelasoft"
                    }
                  >
                    Former Navantis CEO Joins iTelaSoft
                  </Link>
                  <p className="card-text">
                    One of Sri Lanka’s cutting-edge tech companies, iTelaSoft,
                    recently announced its second consecutive year of rapid
                    growth following the entry of former Navantis Chief
                    Executive Officer, Indaka Raigama […]
                  </p>
                  <Link
                    className="btn-link read-more"
                    to={
                      "/noteworthy/former-navantis-ceo-gives-major-impetus-to-itelasoft"
                    }
                  >
                    Continue Reading
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="card news-box">
                <Link
                  className="wr-card-img"
                  to={"/noteworthy/itelasoft-night-2018"}
                >
                  <img alt="News" className="card-img-top" src={n5} />
                </Link>
                <div className="card-body">
                  <Link
                    className="card-title"
                    to={"/noteworthy/itelasoft-night-2018"}
                  >
                    iTelaSoft Night 2018
                  </Link>
                  <p className="card-text">
                    The annual company gathering was held on the 12th of
                    January, 2018, at Best Western Elyon Colombo. The team was
                    able to recap the events that took place in 2017 and discuss
                    the strategies to follow in […]
                  </p>
                  <Link
                    className="btn-link read-more"
                    to={"/noteworthy/itelasoft-night-2018"}
                  >
                    Continue Reading
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="home-getting-touch mt-5">
                <div className="getting-touch">
                  <GetInTouchbtn textdce={"Let’s talk!"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "noteworthy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Nnoteworthy;
